import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';

interface ComponentProps {
  label: string;
  valueText: string;
  iconPath?: string;
  showHr?: boolean;
}

export const VerticalFieldAndValueWithIcon: React.FC<ComponentProps> = ({
  label,
  valueText,
  iconPath,
  children,
  showHr = false,
}) => {
  return (
    <>
      <Grid>
        <img src={iconPath} />
        <Content>
          <LabelContainer>
            <Label>{label}</Label>
          </LabelContainer>
          <ContentInner>{valueText}</ContentInner>
        </Content>
      </Grid>
      {showHr && <Hr />}
    </>
  );
};

const Hr = styled.hr`
  border: 1px solid var(--ringil-base-quaternarycolor);
  width: 100%;
  color: Red;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
`;

const LabelContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: var(--ringil3-primary-color);
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 12px;
  /* Content/Secondary */
  color: var(--ringil3-gr4);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
