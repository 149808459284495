import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';
import { InputText } from 'primereact/inputtext';
import { isNullOrUndefined } from '@utils/utils';
import { RowWithGap } from '@components/common/styled';

interface ComponentProps {
  suffix: string | React.ReactNode;
  inputTextPosition?: 'left' | 'right';
  value: string | number;
  onChange: (value: string) => void;
  button?: JSX.Element;
  readOnly?: boolean;
}

const InputTextWithSuffix: React.FC<ComponentProps> = ({
  value,
  onChange,
  suffix,
  inputTextPosition = 'left',
  button,
  readOnly = false,
}) => {
  return (
    <RowWithGap>
      <InputWrapper className={'r-input-wrapper'}>
        <InputText
          readOnly={readOnly}
          style={{ border: 'none', textAlign: inputTextPosition }}
          value={isNullOrUndefined(value) ? '' : value}
          onChange={(e) => onChange(e.target.value)}
        />
        {suffix && <Suffix>{suffix}</Suffix>}
      </InputWrapper>
      {button}
    </RowWithGap>
  );
};

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border: 1px solid var(--content-secondary);
  gap: 0.5rem;
  height: 39px;
  background-color: var(--base-basesecondary);
`;

const Suffix = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem 0 0;
`;

export default InputTextWithSuffix;
