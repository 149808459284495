import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';
import { Clickable } from '@components/ringil3/styles';
import COHeadling from '@components/ringil3/COHeadling/COHeadling';

interface ComponentProps {
  onClose: () => void;
  headingText: string;
  closeIcon: JSX.Element;
}

const Panel: React.FC<PropsWithChildren<ComponentProps>> = ({ children, onClose, closeIcon, headingText }) => {
  return (
    <PanelInner>
      {headingText && (
        <PanelHeader>
          <COHeadling variant={'h4'}>{headingText}</COHeadling>
          <Clickable onClick={(e) => (onClose ? onClose() : {})}>{closeIcon}</Clickable>
        </PanelHeader>
      )}

      <PanelContent>{children}</PanelContent>
    </PanelInner>
  );
};

const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PanelInner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--base-basesecondary);
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;
`;

const PanelContent = styled.div`
  display: flex;
`;

export default Panel;
