import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'api/api';
import { useTranslation } from 'next-i18next';
import { MultiSelect } from 'primereact/multiselect';
import { dumpVars, isNotNullOrUndefined } from '@utils/utils';
import useTranslationLgs from '@hooks/i18n/useTranslation';
import { CompanyLocationRampWithOpeningHoursOutDTO } from '@api/logsteo-api.v2';
import { Dropdown } from 'primereact/dropdown';
import companyId from '../../../../pages/admin/company/[companyId]';

interface ComponentProps {
  value: string;
  onChange: (value: string) => void;
  companyLocationId: string;
  companyId: string;
}

const InputCompanyRamp: React.FC<ComponentProps> = ({ value, onChange, companyLocationId, companyId }) => {
  const { getSharedRampsForCompanyLocation } = useContext(ApiContext);
  const [data, setData] = useState<CompanyLocationRampWithOpeningHoursOutDTO[]>([]);

  useEffect(() => {
    const ab = new AbortController();
    if (isNotNullOrUndefined(companyLocationId)) {
      getSharedRampsForCompanyLocation(companyLocationId, companyId, (d) => {
        if (!ab.signal.aborted) {
          setData(d);
        }
      });
    }
    return () => {
      ab.abort();
    };
  }, [companyLocationId]);

  return (
    <>
      <Dropdown
        options={data}
        optionLabel={'name'}
        optionValue={'rampId'}
        onChange={(e) => onChange(e.value)}
        value={value}
      />
    </>
  );
};

export default InputCompanyRamp;
