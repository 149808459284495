import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';
import { CargoTemplateWithoutIdDTO } from '@api/logsteo-api.v2';
import { Dropdown } from 'primereact/dropdown';
import { ApplicationContext } from '@components/ApplicationContext/ApplicationContext';
import { dumpVars } from '@utils/utils';

interface ComponentProps {
  skuCode: string;
  onChange: (code: string) => void;
}

const InputSKU: React.FC<ComponentProps> = ({ skuCode, onChange }) => {
  const { tr } = useTranslationLgs();
  const a = useContext(ApplicationContext);

  return (
    <>
      <Dropdown
        value={skuCode}
        options={a.cargoTemplates}
        onChange={(e) => onChange(e.target.value)}
        optionLabel="name"
        optionValue={'code'}
      />
    </>
  );
};

export default InputSKU;
