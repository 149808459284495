import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';
import { Sidebar } from 'primereact/sidebar';
import RingilButton from '@components/ringil3/Buttons/RingilButton';

interface ComponentProps {
  visible: boolean;
  heading: string | JSX.Element;
  onContinue: () => void;
  onBack: () => void;
  loading?: boolean;
  continueLabel?: string | JSX.Element;
  ready?: boolean;
  showSaveButton?: boolean;
}

const RingilSidebar: React.FC<PropsWithChildren<ComponentProps>> = ({
  visible,
  heading,
  onContinue,
  onBack,
  children,
  loading = false,
  continueLabel,
  ready = true,
  showSaveButton = true,
}) => {
  const { tr } = useTranslationLgs();
  return (
    <>
      <Sidebar
        onHide={onBack}
        visible={visible}
        style={{ width: '550px', backgroundColor: 'var(--ringil3-base-color)' }}
        position={'right'}
        icons={() => <SidebarHeader>{heading}</SidebarHeader>}
        closeOnEscape={true}
        dismissable={false}
        blockScroll={true}
      >
        {children}
        <SidebarButtons>
          {showSaveButton && (
            <RingilButton
              label={continueLabel ? continueLabel : tr(`RingilSidebar.continue`, `Continue`)}
              onClick={() => onContinue()}
              mode={'filled'}
              size={'big'}
              loading={loading}
            />
          )}

          <RingilButton
            label={tr(`RingilSidebar.back`, `Back`)}
            onClick={() => onBack()}
            mode={'text-only'}
            size={'big'}
          />
        </SidebarButtons>
      </Sidebar>
    </>
  );
};

const SidebarButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SidebarHeader = styled.div`
  display: flex;
  flex: 1;
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 24px;
  padding: 0.5rem 0;
`;
export default RingilSidebar;
