import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';
import { Clickable } from '@components/ringil3/styles';

interface ComponentProps {
  icon?: JSX.Element;
  text?: string;
  onClick?: () => void;
}

const LinkWithIcon: React.FC<ComponentProps> = ({ icon, text, onClick }) => {
  return (
    <>
      <Clickable onClick={(e) => (onClick ? onClick() : {})}>
        <RowWithGap>
          {icon && <span>{icon}</span>}
          {text && <span>{text}</span>}
        </RowWithGap>
      </Clickable>
    </>
  );
};

const RowWithGap = styled.div`
  display: flex;
  column-gap: 0.5rem;
`;

export default LinkWithIcon;
