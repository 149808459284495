import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';

interface ComponentProps {
  text: string;
}

const COSelect: React.FC<ComponentProps> = ({ text }) => {
  return <COSelectInner>{text}</COSelectInner>;
};

const COSelectInner = styled.div`
  display: flex;
  border-radius: 20px;
  padding: 1rem;
  background-color: var(--ringil3-content-quaternary);
  white-space: nowrap;

  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
`;

export default COSelect;
