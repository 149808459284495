import React, { createRef, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled, { css } from 'styled-components';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';
import { Icon } from '@components/ringil3/types';
import { GrayText } from '@components/ringil3/styles';
import { dumpVars, isNotNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  tabs: TabProps[];
  contentSelectorClassName: string;
}

const OrderTabs: React.FC<ComponentProps> = ({ tabs, contentSelectorClassName }) => {
  const { tr } = useTranslationLgs();

  useEffect(() => {
    const tabs = document.querySelectorAll('.tab');
    const tabSections = document.querySelectorAll(`.${contentSelectorClassName}`);

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.boundingClientRect.top < 280) {
            const index = Array.from(tabSections).indexOf(entry.target);
            tabs.forEach((tab) => {
              tab.classList.remove('active');
            });
            tabs[index].classList.add('active');
          }
        });
      },
      {
        threshold: [0.5, 0],
        rootMargin: '-10px 0px 0px 0px',
      },
    );

    tabSections.forEach((sections) => {
      observer.observe(sections);
    });
  }, [tabs]);

  return (
    <TabsInner>
      {tabs.map((tab, tabIndex) => {
        return (
          <Tab
            key={tabIndex}
            icon={isNotNullOrUndefined(tab.icon) ? tab.icon : null}
            label={tab.label}
            active={tab.active}
            anchorId={tab.anchorId}
            className="tab"
          ></Tab>
        );
      })}
    </TabsInner>
  );
};

export interface TabProps {
  icon: Icon;
  label: string;
  active: boolean;
  anchorId: string;
  className: string;
}

const Tab: React.FC<TabProps> = ({ icon, label, active, anchorId, className }) => {
  const ref = createRef();

  const onClick = (e) => {
    const target = document.getElementById(anchorId);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <TabInner ref={ref} className={className} onClick={(e) => onClick(e)}>
      <TabInnerContent>
        {isNotNullOrUndefined(icon) && <img src={icon.iconPath} />}
        <GrayText>{label}</GrayText>
      </TabInnerContent>
    </TabInner>
  );
};

const TabInnerContent = styled.div`
  display: flex;
  padding: 0 24px;
  gap: 1rem;
  white-space: nowrap;
`;

const TabInner = styled.div<Pick<TabProps, 'active'>>`
  display: flex;
  padding: 1rem 0;
  cursor: pointer;
  background-color: var(--ringil3-quaternary-color);
  * {
    text-transform: uppercase;
    font-weight: 600;
  }

  &.active {
    background-color: white;
    border-bottom: 2px solid var(--ringil3-brand-color);
    color: var(--ringil3-brand-color);
    div {
      color: var(--ringil3-brand-color);
    }
  }
`;

const TabsInner = styled.div`
  display: flex;
  margin: 1.5rem 0 0.5rem 0;
  gap: 0.5rem;
  border-bottom: 2px solid var(--ringil3-brand-color);
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--ringil3-base-tertiary-color);
`;

export default OrderTabs;
