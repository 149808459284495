import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';
import { RowWithGap, RowWithSpaceBetween } from '@components/common/styled';
import { Clickable } from '@components/ringil3/styles';
import AddButton from '@components/ringil3/Buttons/AddButton';
import { VerticalFieldAndValue } from '@components/ringil3/Containers/VerticalFieldAndValue';
import { InputTextarea } from 'primereact/inputtextarea';
import { isBlank, isNullOrUndefined } from '@utils/utils';

interface ComponentProps {
  note: string;
  onChanges: (value: string) => void;
  noteLabel?: string;
}

const InputNoteWithLink: React.FC<ComponentProps> = ({ note, noteLabel, onChanges }) => {
  const { tr } = useTranslationLgs();
  const [open, setOpen] = useState(false);

  return (
    <InputNoteWithLinkWrapper>
      {!open && isBlank(note) && (
        <Clickable onClick={(e) => setOpen(true)}>
          <RowWithGapCentered>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.70734 8.70711H16V7.29289H8.70734L8.70734 0H7.29313L7.29313 7.29289H0V8.70711H7.29313V16H8.70734V8.70711Z"
                fill="#FE5000"
              />
            </svg>
            <div>{noteLabel}</div>
          </RowWithGapCentered>
        </Clickable>
      )}

      {(note || open) && (
        <VerticalFieldAndValue label={noteLabel} labelMode={'normal'}>
          <InputTextarea
            value={isNullOrUndefined(note) ? '' : note}
            onChange={(e) => onChanges(e.target.value)}
            rows={4}
          />
        </VerticalFieldAndValue>
      )}
    </InputNoteWithLinkWrapper>
  );
};

const RowWithGapCentered = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
`;

const InputNoteWithLinkWrapper = styled.div`
  gap: 24px;
  display: inline-block;
  cursor: pointer;
  margin: 1rem 0;
`;

export default InputNoteWithLink;
