import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled, { css } from 'styled-components';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';

interface ComponentProps {
  icon?: JSX.Element;
  buttons?: JSX.Element;
  variant: 'info' | 'warning' | 'error' | 'success';
}

const COInlineMessage: React.FC<PropsWithChildren<ComponentProps>> = ({ icon, children, variant, buttons }) => {
  return (
    <COInlineMessageInner variant={variant}>
      <GridWithIcon>
        {icon && <ImgWrapper>{icon}</ImgWrapper>}
        <NoteInner>{children}</NoteInner>
      </GridWithIcon>
    </COInlineMessageInner>
  );
};

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoteInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;

const GridWithIcon = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  flex: 1;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.02em;
    /* Black */
    color: #343333;
    margin: 0;
  }
`;

const COInlineMessageInner = styled.div<Pick<ComponentProps, 'variant'>>`
  display: flex;
  ${(props) =>
    props.variant &&
    props.variant === 'info' &&
    css`
      background-color: var(--ringil3-content-quaternary);
    `};
  ${(props) =>
    props.variant &&
    props.variant === 'warning' &&
    css`
      background-color: var(--ringil3-brand-light-color);
    `};
  ${(props) =>
    props.variant &&
    props.variant === 'error' &&
    css`
      background-color: var(--complementary-errormedium);
    `};
  ${(props) =>
    props.variant &&
    props.variant === 'success' &&
    css`
      background-color: var(--complementary-successmedium);
    `};
`;

export default COInlineMessage;
