import styled, { css } from 'styled-components';

export const Ringil3H1 = styled.h1`
  color: var(--ringil3-gr4);
  font-weight: 400;
`;

export const Ringil3H2 = styled.h2`
  color: black;
  font-size: 1.375rem;
  font-weight: 400;
`;

export const Ringil3H3 = styled.div`
  color: #343333;
  font-size: 1rem;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Ringil3BadgeRow = styled.div`
  flex-direction: row;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
`;

export const Panel = styled.div`
  border-radius: 8px;
  background-color: var(--ringil3-base-color);
  margin: 0.5rem 0;
  padding: 1rem 1rem;
  flex: 1;
`;

export const Paragraph = styled.p`
  font-size: 15px;
  color: var(--ringil3-gr4);
`;

export const Card = styled.div`
  border-radius: 1rem;
  background-color: var(--ringil3-base-secondary-color);
  padding: 1.5rem 1rem;
  width: 100%;
`;

export const GrayText = styled.div`
  color: var(--ringil3-gr4);
`;

export const LargeText = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
`;

export const HorizontalLine = styled.hr<{ variant: 'brand-light' | 'normal' | 'tertiary' | 'quaternary' }>`
  width: 100%;
  margin: 0rem 0;

  ${(props) =>
    props.variant === 'normal' &&
    css`
      border-bottom: 1px solid var(--ringil3-gr4);
    `}

  ${(props) =>
    props.variant === 'brand-light' &&
    css`
      border-bottom: 1px solid var(--ringil3-brand-light-color);
    `}
    
  ${(props) =>
    props.variant === 'tertiary' &&
    css`
      border-bottom: 1px solid var(--ringil3-content-tertiary-color);
    `}

  ${(props) =>
    props.variant === 'tertiary' &&
    css`
      border-bottom: 1px solid var(--ringil3-content-quaternary);
    `}
`;

export const ClickableCursor = styled.div`
  display: inline-block;
  cursor: pointer;
`;

export const Clickable = styled.div`
  display: inline-block;
  cursor: pointer;
  color: var(--ringil3-brand-color);
`;

export const ClickableLink = styled.a`
  display: inline-block;
  cursor: pointer;
  color: var(--ringil3-brand-color);
`;

export const Ringil3Note = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--ringil3-gr4);
`;

export const InputAndButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
`;

/**
 * Used for new pages - should be refactored
 */
export const Test = styled.div`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: black;
  gap: 1rem;

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--ringil3-brand-color);
    background: var(--ringil3-brand-color);
  }
`;

export const TwoColGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

export const OrderWrapper = styled.div`
  flex-direction: column;
  display: flex;
  gap: 1rem;

  margin: 0 -1rem;
  padding: 1rem;
`;

export const ColOdd = styled.div`
  display: flex;
  flex-direction: column;

  ${OrderWrapper}:nth-child(odd) {
    background-color: var(--base-basequaternary);
  }
`;

export const TwoColGridWithoutGap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Anchor = styled.div`
  display: block;
  position: relative;
  top: -70px;
  visibility: hidden;
`;
