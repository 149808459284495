import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';
import Image from 'next/image';

interface ComponentProps {
  onClick: () => void;
}

const AddButton: React.FC<ComponentProps> = ({ onClick }) => {
  return (
    <ButtonInner onClick={(e) => onClick()}>
      <Image src={'/images/icons/ringil3/buttons/add.svg'} width={16} height={16} />
    </ButtonInner>
  );
};

const ButtonInner = styled.button`
  padding: 4px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: var(--ringil3-base-secondary-color);
  border: 1px solid var(--ringil3-gr2);
  box-shadow: 0px 4px 4px rgba(254, 80, 0, 0.15);
  flex-shrink: 1;
`;

export default AddButton;
