import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { ApiContext } from 'api/api';
import useTranslationLgs from 'hooks/i18n/useTranslation';

interface ComponentProps {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
}

const COHeadling: React.FC<PropsWithChildren<ComponentProps>> = ({ variant, children }) => {
  return (
    <>
      {variant === 'h1' && <H1Inner>{children}</H1Inner>}
      {variant === 'h2' && <H2Inner>{children}</H2Inner>}
      {variant === 'h3' && <H3Inner>{children}</H3Inner>}
      {variant === 'h4' && <H4Inner>{children}</H4Inner>}
      {variant === 'h5' && <H5Inner>{children}</H5Inner>}
    </>
  );
};

const H1Inner = styled.h1`
  font-style: normal;
  white-space: nowrap;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: -0.02em;
  margin: 0;
  /* GR4 */
  color: var(--ringil3-gr4);
`;

const H2Inner = styled.h2`
  font-style: normal;
  white-space: nowrap;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: -0.02em;
  margin: 0;
  /* GR4 */
  color: var(--ringil3-gr4);
`;

const H3Inner = styled.h3`
  font-style: normal;
  white-space: nowrap;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: var(--content-black);
  margin: 0;
`;

const H5Inner = styled.div`
  font-style: normal;
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--ringil3-gr4);
  margin: 0;
`;

const H4Inner = styled.div`
  color: var(--ringil3-gr4);
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

export default COHeadling;
